/* FILE: LoadingScreen.css */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f5f5f5;
  animation: fadeIn 0.5s ease-in;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.logo {
  max-width: 200px;
  animation: pulse 2s infinite;
}

.spinner {
  width: 50px;
  height: 50px;
  position: relative;
}

.spinner-inner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #1890ff;
  border-right-color: #52c41a;
  border-bottom-color: #faad14;
  animation: spin 1s linear infinite;
}

.progress-bar {
  width: 200px;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Add to LoadingScreen.css */
.loading-text-container {
  position: relative;
  min-width: 280px;
  text-align: center;
}

.loading-text {
  display: inline-block;
}

.loading-dots {
  position: absolute;
  display: inline-block;
}