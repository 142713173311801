.pig-image-section {
  text-align: center;
  padding: 3rem 0;
}

.pig-image-section img {
  max-width: 100%;
  height: auto;
  max-height: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.price-card {
  transition: all 0.3s ease;
}

.price-card:hover {
  transform: translateY(-5px);
}

.price-card.highlighted {
  position: relative;
  z-index: 1;
}

.price-card ul li {
  display: flex;
  align-items: center;
  margin: 8px 0;
}
